import React, { Component } from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 24px;
	grid-template-rows: minmax(100px, auto);

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 1200px) {
	}
`

export default class NotFoundPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			width: 0,
			height: 0
		}

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}

	componentDidMount() {
		this.updateWindowDimensions()

		window.addEventListener('resize', this.updateWindowDimensions)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	render() {
		return (
			<Layout>
				<SEO title="404: Not found" />
				<Container>
					<iframe
						title="Chillhop Livestream from YouTube"
						width={Math.min(this.state.width * 0.92, 1200)}
						height={Math.min(this.state.width * 0.92 * (9 / 16), 675)}
						src="https://www.youtube.com/embed/hHW1oY26kxQ?autoplay=1&playsinline=1"
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							alignSelf: 'center',
							justifySelf: 'center',
							gridColumn: '1 / span 12',
							gridRow: '1'
						}}
					/>

					<TextLargeAndSmallBlock
						largeText={'/404/'}
						smallText={`
							Two roads diverged in a yellow wood,
							And sorry I could not travel both
							And be one traveler, long I stood
							And looked down one as far as I could
							To where it bent in the undergrowth;

							Then took the other, as just as fair,
							And having perhaps the better claim,
							Because it was grassy and wanted wear;
							Though as for that the passing there
							Had worn them really about the same,

							And both that morning equally lay
							In leaves no step had trodden black.
							Oh, I kept the first for another day!
							Yet knowing how way leads on to way,
							I doubted if I should ever come back.

							I shall be telling this with a sigh
							Somewhere ages and ages hence:
							Two roads diverged in a wood, and I—
							I took the one less traveled by,
							And that has made all the difference.`}
					/>
				</Container>
			</Layout>
		)
	}
}
